import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Routes, Route } from "react-router-dom";
import InvestNow from "../components/InvestNow";
import Navbar from "../components/Navbar";
import { useSpinner } from "../context/SpinnerContext";
import Banner from "../components/Banner";
import PresalePrivada from "../components/PresalePrivada";
import PresalePublic from "../components/PresalePublic";
import PresalePublic2 from "../components/PresalePublic2";
import PriceToken from "../components/PriceToken";
import Admin from "../components/Admin";

const AppRouter = () => {
  const { isSpinnerShown, spinnerMessage, showSpinner, hideSpinner } =
    useSpinner();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = "/fondo.png";
    image.onload = () => setImageLoaded(true);
  }, []);

  const backgroundStyle = {
    backgroundImage: imageLoaded ? `url('/bg.png')` : "none",
    backgroundColor: imageLoaded ? "transparent" : "#540070", // Fallback color
    // Other styles...
  };

  return (
    <>
      <Toaster
        position='top-right'
        toastOptions={{
          style: {
            background: "#363636",
            color: "#fff",
          },
          iconTheme: {
            primary: "#9F0",
            secondary: "#000",
          },
        }}
      />

      <div className='bg-[#540070]'>
        <Routes>
          {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}

          <Route path='/' element={<InvestNow />} />
          <Route path='/admin' element={<Admin />} />
          <Route path='/presale-privad' element={<PresalePrivada />} />
          <Route path='/presale-public' element={<PresalePublic />} />
          <Route path='/presale-new' element={<PresalePublic2 />} />
        </Routes>
      </div>
    </>
  );
};
export default AppRouter;
