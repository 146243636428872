import React, { useState, useContext, useEffect } from 'react';
import Web3Context from "../context/Web3Context";
import { motion, useReducedMotion } from 'framer-motion';
import { BigNumber, utils } from 'ethers';
import { useSPresaleNew } from '../context/PresaleHandleNew';
import CardRefPresale from './CardRefPresale';
import refHandler from '../hooks/utils';


function PresalePublic2() {


  const [recaudacion, setRecaudacion] = useState(0);
  const maxValue = 8;
  const [amount, setamount] = useState(0);
  const [bbh, setbbh] = useState(0);
  const HARCAP = 300;
  const [referred, setReferred] = useState("");

  const {  invest, allData, userData, balanceOf, withdraw } =
  useSPresaleNew();
  const { accounts, isLoaded, setupdate, update1 } = useContext(Web3Context);

  useEffect(() => {
    //setRecaudacion(presaleState?.HARCAP);
    setRecaudacion(allData?.totalDeposits_);
    // setRecaudacion(100);
    return () => {};
  }, [allData]);

  useEffect(() => {
    console.log(amount);
    setbbh(amount * 1125);
    return () => {};
  }, [amount]);

  function percentage(input) {
    return (input * 100) / 200;
  }
  // useEffect(() => {
  //   // percentage
  //   return () => {};
  // }, [presaleState]);

  function buyToken(amount_) {
    console.log(amount_);
    
    invest(amount_, false);
  }
  // buyer:"",
  //        tokenAmount:"",
  //        investAmount:"",
  //        hasWithdrawn:"",

  const [acount_, setacount_] = useState('0x');

  useEffect(() => {
    console.log();
    const start = '0X...';
    const end = accounts.slice(38);
    setacount_(`${start}...${end}`);
    return () => {};
  }, [accounts]);


  useEffect(() => {
    const referred_ = refHandler();
    if (referred_ != "0x373Ea14E600A474F30fc27b39dAd0253d3094dcf")
      setReferred(referred_);
  }, []);

  return (
    <>
    <div className=" bg-cover bg-center bg-fixed bg-black"
      style={{ backgroundImage: `url(/fondo.png)`}} >



      <div className="mx-auto grid  max-w-7xl items-center  lg:grid-cols-2">
        <div
          className="relative  mt-12 px-4"
        
        >
        <div/>
        </div>
        <div className="mt-10 mb-20" >
          <div className="max-w-4xl rounded-lg bg-gray-200/50  p-3 text-[#9900cb]">
          <div className="">
        <motion.div>
          <div>

            <div className="my-6 flex justify-center">
            <img src="/public-presale.png" className="h-auto w-52" />
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className=" px-12 pb-8 sm:px-3">
                <div>
                  <div>
      
                   

                    <div className="mt-1 rounded-md shadow-sm ">
                      <input
                        autoFocus
                        id="number"
                        type="number"
                        min={0.1}
                        placeholder="0"
                        max={maxValue}
                        onChange={(e) => {
                          if (
                            e.target.value == '' ||
                            Number(e.target.value) < 0.1
                          )
                            setamount(0.1);
                          else setamount(e.target.value);
                        }}
                        aria-describedby="price-currency"
                        className="block w-full rounded-md border-gray-200 bg-green-700 pl-7 text-2xl text-white shadow-sm placeholder:text-white focus:border-purple-900 focus:ring-purple-700"
                      />
                      
                      <div className="mt-0.5 text-slate-200">
                        BNB Amount: {balanceOf}
                      </div>
                    </div>
                    <div className="mt-7">
                      <label
                        htmlFor="referred"
                        className="block mb-1 ml-1 text-sm uppercase font-bold text-white"
                      >
                        Referred
                      </label>

                      <input
                        onChange={(e) => {
                          setReferred(e.target.value);
                        }}
                        className="block w-full px-3 py-2  text-xs font-bold text-center text-white placeholder-gray-400 bg-gradient-to-r from-green-900 to-green-800 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-400  sm:text-xs"
                        id="referred"
                        name="referred"
                        type="text"
                        required
                        defaultValue={referred}
                      />
                    </div>
                  </div>
                  <div className="mt-10">                    
                    <button className="block w-full rounded-lg  bg-slate-900 px-2 py-2 text-center font-bold text-white sm:text-sm"
                    onClick={() => buyToken(amount)}
                    >
                      Buy
                    </button>
                  </div>
                  <div className="mt-6">                    
                    <button className="block w-full rounded-lg  bg-slate-900 px-2 py-2 text-center font-bold text-white sm:text-sm"
                    onClick={() => withdraw()}
                    >
                      Claim
                    </button>
                  </div>

                  <div className="mt-6 flex w-full justify-center shadow-sm">
                  </div>
                  <p className="mt-4 text-center text-xl font-bold text-black">
                    $BILL price: 0.20 USD <br /> Only Participation: 0.1 - 8 BNB
                    <br />
                    Hardcap: 300 BNB
                  </p>
                  <CardRefPresale amountRef1={userData?.referrerCount_[0]} amountRef2={userData?.referrerCount_[1]}  amountRef3={userData?.referrerCount_[2]}/>
                  <div className="mt-10">
                    <label
                      htmlFor="number"
                      className=" text-md my-2 block text-center font-medium leading-5 text-black"
                    >
                      You will receive
                    </label>
                    <p className="block w-full rounded-lg  bg-slate-900 px-2 py-2 text-center font-bold text-white sm:text-sm">
                      {bbh} BILL
                    </p>
                  </div>
                  <div className="-mt-px flex divide-x divide-[#8f80ba]">
                    <div className="flex w-0 flex-1 flex-col justify-center">
                      <a className="items-center justify-center rounded-bl-lg border border-transparent py-4  text-center text-sm font-medium text-slate-100 ">
                        <span className="">Your Invest:</span>
                        <p>{userData?.totalEarnings_}</p>
                      </a>
                    </div>
                    <div className="-ml-px flex w-0 flex-1 flex-col justify-center">
                      <a className="items-center justify-center rounded-bl-lg border border-transparent py-4  text-center text-sm font-medium text-slate-100 ">
                        <span className="">Your Tokens:</span>
                        <p>{userData?.totalBonus_}</p>
                      </a>
                    </div>
                  </div>
                  <span className="mt-6 flex justify-center text-sm font-semibold leading-tight text-slate-100 md:text-base">
                    Collect: {recaudacion + 4.221} {'BNB'}
                  </span>
                  <div className="mt-1 flex justify-center">
                    <div className="flex h-full w-60 items-center lg:flex-row ">
                      <div className="text-md mb-0 flex items-center justify-center uppercase text-gray-500">
                        {/* <span className="mr-2">completed</span> */}
                      </div>
                      <div className="relative h-1 w-full max-w-lg rounded-full bg-slate-800">
                        <motion.div
                          animate={{
                            width: `${percentage(
                              Number.parseInt(recaudacion + 4.221),
                            )}%`,
                          }}
                          initial={{
                            width: `${percentage(
                              Number.parseInt(recaudacion + 4.221),
                            )}%`,
                          }}
                          transition={{
                            duration: 0.5,
                            type: 'spring',
                            mass: 0.5,
                          }}
                          className="h-1 bg-green-600 transition-transform duration-75 ease-in-out"
                        />
                        {/* <div
          css={{left: '75%'}}
          className={`w-5 h-5 -mt-3 rounded-full absolute border-2 border-gray-300 bg-background ${
            moduleProgress.percentComplete >= 75 ? 'border-blue-500' : ''
          }`}
        /> */}
                        <div
                          style={{ left: '99%' }}
                          className={`absolute -mt-3 h-5 w-5 rounded-full border-2  bg-white `}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
         
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default PresalePublic2;
