import React, { useRef, useState, useContext, useEffect } from "react";
import TokenHandle from "../context/TokenHandle";
import Web3Context from "../context/Web3Context";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import ModalTableReferral from "./ModalTableReferral";

const dataRef_ = [
    {
      level: 1,
      porcentaje: '10%',
      amount: 0,
    },
    {
      level: 2,
      porcentaje: '3%',
      amount: 0,
    },
    ,
    {
      level: 3,
      porcentaje: '2%',
      amount: 0,
    }
  ];

function CardRefPresale({amountRef,amountRef1,amountRef2,amountRef3}) {
  const {
    userData,
    allData,
    invest,
    withdraw,
    reinvestment,
    forceWithdraw,
    updateHandle,
  } = useContext(TokenHandle);
  const { accounts, isLoaded } = useContext(Web3Context);
  const [amount, setamount] = useState(0.1);

  const [copyText, setcopyText] = useState("");
  const [copyTextWallet, setcopyTextWallet] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isCopied1, setIsCopied1] = useState(false);
  const [dataRef, setdataRef_] = useState(dataRef_);

  useEffect(() => {
    // console.log(userData, "data_data_data_data_data_data_");

    const data_ = [
      {
        level: 1,
        porcentaje: '10%',
        amount: amountRef1,
      },
      {
        level: 2,
        porcentaje: '3%',
        amount: amountRef2,
      },
      ,
      {
        level: 3,
        porcentaje: '2%',
        amount: amountRef3
      },
    ];

    setdataRef_(data_);
    return () => {};
  }, [userData]);

  useEffect(() => {    
    if (!isLoaded) return;
    const link = `${window.location.origin}/presale-new?ref=${accounts}`;
    setcopyText(link);
    setcopyTextWallet(accounts);
    // `${window.location.href}?ref=${accounts}`
    return () => {};
  }, [accounts, isLoaded]);

  const onCopy = () => {
    setIsCopied(true);
    toast.success("Personal link Copied Successfully");
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
    console.log("hola");
  };

  const onCopyWallet = () => {
    setIsCopied1(true);
    toast.success("Personal wallet Copied Successfully");
    setTimeout(() => {
      setIsCopied1(false);
    }, 1000);
    console.log("hola");
  };
  return (
    <>

      <div className="px-2">
    
        <h1 className="tlt text-center text-black text-xl">            Referral Link</h1>
        <div className="mx-1 pb-2 lg:mx-3">
            {dataRef.map((card, i) => (
              <div key={i+"pr"} className="flex-card" >
                <p className={`text-sm font-bold uppercase text-black`}>
                  Level {card.level} - {card.porcentaje}:
                </p>
                <p className={`text-sm font-bold text-black`}>
                {card.amount}
                </p>
              </div>
            ))}
          </div>
        <div>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              type="text"
              name="referral-link"
              id="referral-link"
              value={copyText}
              className="block w-full truncate rounded-md border-black bg-white py-3 text-lg font-light  text-black focus:border-red-900 focus:ring-slate-900"
            />
             
       
          </div>
        </div>

        <div className="flex justify-center pt-1">
          <CopyToClipboard
            text={copyText}
            onCopy={() => {
              onCopy();
            }}
            className="flex w-full cursor-pointer items-center justify-center rounded-md border border-white bg-black px-6 py-3 text-xl font-extrabold uppercase text-white shadow-sm hover:brightness-110 disabled:opacity-20 sm:px-8 "
          >
            <div className="flex justify-center">
              <p className="text-sm ">{isCopied ? 'COPIED' : 'COPY'}</p>
            </div>
          </CopyToClipboard>
        </div>
       
      </div>
  
  </>
  );
}

export default CardRefPresale;
