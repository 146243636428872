import {utils} from 'ethers'
const refHandler =()=>{
    let referrer =""      
    new URLSearchParams(window.location.href).forEach((value,key,c)=>{           
      if(value.search("Trust")==-1){        
      if(key="ref")
      referrer=value.split("#")[0]
      if(window.navigator.userAgent.search("iPhone")>=0 )        {
      referrer=value
    }
  }  
  })     
  
    if(referrer==""){
      const random = Math.floor(Math.random() * 100)
      referrer =random>50?"0x81eEC072279a1df66D894A054B3c602d6B4BBB03":"0x81eEC072279a1df66D894A054B3c602d6B4BBB03"
    }      
    

    return referrer  
  }
export default refHandler

