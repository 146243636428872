import React, { useContext, useState, useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import TokenHandle from '../context/TokenHandle';
import clsx from 'clsx';
import { utils } from 'ethers';
import refHandle from '../hooks/utils';
import Modal from './Modal';

function Stake() {
  const [amount, setamount] = useState(0.01);
  const [referred, setReferred] = useState('');
  const [errorPrice, setErrorPrice] = useState('');
  const [ModalOpen, setModalOpen] = useState(false);
  const { invest, userData, allData, isApprove, approveHandle } =
    useContext(TokenHandle);

  useEffect(() => {
    const referred_ = refHandle();
    if (referred_ != '0x373Ea14E600A474F30fc27b39dAd0253d3094dcf')
      setReferred(referred_);
  }, []);

  useEffect(() => {
    setErrorPrice('');
    if (amount < 21) setErrorPrice(`The minimum invest is 21 BILL`);
  }, [amount]);

  const balance = parseFloat(userData.balance_) || 0;
  const totalWithdrawn_ = parseFloat(userData.totalWithdrawn_) || 0;
  const totalDeposits = parseFloat(userData.totalDeposits_) || 0;
  const bonusWithdraw_c = parseFloat(userData.bonusWithdrawC) || 0;

  let progressPercentage;

  if (totalDeposits === 0) {
    progressPercentage = 0;
  } else if (
    !isNaN(balance) &&
    !isNaN(totalWithdrawn_) &&
    !isNaN(bonusWithdraw_c)
  ) {
    progressPercentage =
      ((totalWithdrawn_ + balance - bonusWithdraw_c) * 100) / totalDeposits;
  } else {
    progressPercentage = 0;
  }

  return (
    <>
      <div className="xl:flex-shrink-0 xl:w-68  ">
        <div className="py-6 pl-4 pr-6 sm:pl-6 lg:pl-8 xl:pl-0">
          <div className="flex items-center justify-between">
            <div className="flex-1 space-y-8">
              {/* Stake */}
              <div className="mt-8  mx-auto  max-w-2xl lg:w-96">
                <div className="bg-2 px-4 py-8 w-full shadow sm:px-10">
                  <div className="space-y-6">
                    <div>
                      <div className="mt-1">
                        <div>
                          <label
                            htmlFor="amount"
                            className="block mb-1 ml-1 text-sm uppercase font-bold text-white"
                          >
                            Enter Amount
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <img
                                alt="asdasdasd"
                                src="/usdt-yellow.png"
                                className="h-5 w-5 text-gray-400"
                              />
                            </div>
                            <input
                              onChange={(e) => {
                                if (e.target.value == '' || e.target.value == 0)
                                  setamount('21');
                                else setamount(e.target.value);
                              }}
                              className={clsx(
                                'rounded-md',
                                {
                                  'border-red-300 text-red-500 placeholder-red-300 focus:ring-red-500 focus:border-red-500 text-center font-bold bg-[#540070] appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  sm:text-sm':
                                    errorPrice,
                                },
                                {
                                  'text-center font-bold bg-[#540070] text-[#9F0] appearance-none block w-full px-3 py-2   rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-yellow-400 sm:text-sm':
                                    !errorPrice,
                                }
                              )}
                              id="stake"
                              name="stake"
                              type="number"
                              required
                            />
                          </div>
                        </div>

                        {errorPrice && (
                          <>
                            <div className="absolute mt-2 w-60">
                              <p
                                className="text-xs text-red-300"
                                id="price-error"
                              >
                                {errorPrice}
                              </p>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="mt-9">
                        <label
                          htmlFor="referred"
                          className="block mb-1 ml-1 text-sm uppercase font-bold text-white"
                        >
                          Referred
                        </label>
                        <input
                          onChange={(e) => {
                            setReferred(e.target.value);
                          }}
                          className="block w-full px-3 py-2  text-xs font-bold text-center text-[#9F0] bg-[#540070] rounded-md shadow-sm appearance-none   sm:text-xs"
                          id="referred"
                          name="referred"
                          type="text"
                          required
                          readOnly
                          defaultValue={referred}
                        />
                      </div>
                    </div>

                    <div className="pb-7 ">
                      {isApprove ? (
                        <button
                          onClick={() => invest(amount, referred)}
                          className="flex hover:brightness-110 justify-center w-full btn-primary py-2 rounded-md"
                        >
                          INVEST
                        </button>
                      ) : (
                        <button
                          onClick={approveHandle}
                          type="button"
                          className="flex hover:brightness-110 justify-center w-full btn-primary py-2 rounded-md"
                        >
                          Approve
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/*Statitics */}
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                <div className="bg-2 relative flex items-center px-6 py-5 space-x-3 bg-gradient-to-b from-green-500 to-green-700 rounded-lg shadow-sm ">
                  <div className="flex-1 min-w-0">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-md font-black text-white uppercase">
                      Your Balance
                    </p>
                    <div className="mt-2">
                      <div className="relative flex items-start py-3 px-3 rounded-md bg-[#540070]">
                        <div className="min-w-0 flex-1 text-sm">
                          <label
                            htmlFor={`side-`}
                            className="select-none font-medium text-gray-700"
                          >
                            <img
                              alt="asdasd"
                              src="/usdt-yellow.png"
                              className="h-7 w-7 "
                            />{' '}
                          </label>
                        </div>
                        <div className="ml-3 mt-2 flex h-5 font-black items-center text-[#9F0]">
                          {userData.balance_} BILL
                        </div>
                      </div>
                      <div className="">
                        <div className="mt-4 h-2.5 relative  overflow-hidden">
                          <div
                            className="h-2.5 rounded-full bg-purple-600 overflow-hidden"
                            style={{
                              // Asegura que el ancho no exceda el 100% del contenedor
                              width: `${Math.min(
                                progressPercentage / 2,
                                100
                              )}%`,
                            }}
                          />
                        </div>
                      </div>
                      <p className="mt-2 break-all text-white">
                        {progressPercentage <= 200
                          ? `Progreso: ${progressPercentage.toFixed(2)}%`
                          : 'You have reached the goal!'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="hidden bg-2 relative  bg-center pb-12  bg-cover bg-local bg-no-repeat flex items-center px-6 py-5 space-x-3  rounded-lg shadow-sm ">
                  <div className="flex-1 min-w-0">
                    <div className="flex justify-center">
                      <img alt="" src="/logo-2.png" className="w-auto h-16" />
                    </div>

                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-md mt-5 font-black text-white uppercase">
                      Contract Balance
                    </p>
                    <div className="mt-2">
                      <div className="relative flex items-start py-3 px-3 rounded-md bg-[#540070]">
                        <div className="min-w-0 flex-1 text-sm">
                          <label
                            htmlFor={`side-`}
                            className="select-none font-medium text-gray-700"
                          >
                            <img
                              alt="asdasdasd"
                              src="/usdt-yellow.png"
                              className="h-7 w-7 "
                            />{' '}
                          </label>
                        </div>
                        <div className="ml-3 mt-2 flex h-5 font-black items-center text-[#9F0]">
                          {allData.balance_} BILL
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Stake;
