import React, { useRef, useState, useContext, useEffect } from "react";
import TokenHandle from "../context/TokenHandle";
import Web3Context from "../context/Web3Context";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import ModalTableReferral from "./ModalTableReferral";

function CardRef() {
  const {
    userData,
    allData,
    invest,
    withdraw,
    reinvestment,
    forceWithdraw,
    updateHandle,
  } = useContext(TokenHandle);
  const { accounts, isLoaded } = useContext(Web3Context);
  const [amount, setamount] = useState(0.1);

  const [copyText, setcopyText] = useState("");
  const [copyTextWallet, setcopyTextWallet] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isCopied1, setIsCopied1] = useState(false);

  useEffect(() => {    
    if (!isLoaded) return;
    const link = `${window.location.origin}?ref=${accounts}`;
    setcopyText(link);
    setcopyTextWallet(accounts);
    // `${window.location.href}?ref=${accounts}`
    return () => {};
  }, [accounts, isLoaded]);

  const onCopy = () => {
    setIsCopied(true);
    toast.success("Personal link Copied Successfully");
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
    console.log("hola");
  };

  const onCopyWallet = () => {
    setIsCopied1(true);
    toast.success("Personal wallet Copied Successfully");
    setTimeout(() => {
      setIsCopied1(false);
    }, 1000);
    console.log("hola");
  };
  return (
    <div className="mt-9">
      <CopyToClipboard
        text={copyText}
        onCopy={() => {
          onCopy();
        }}
      >
        <div className="flex cursor-pointer items-center w-full max-w-7xl px-8 mx-auto pt-40">
          <div className="mr-4 flex-shrink-0 self-center">
            <img src="/referrals.png" width={60} height={60}  alt="aSASASAS"/>
          </div>
          <div>
            <h4 className="text-lg font-bold text-primary">
              COPY HERE YOUR REFERRAL LINK
            </h4>
          </div>
        </div>
      </CopyToClipboard>
    </div>
  );
}

export default CardRef;
