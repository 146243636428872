import React, { createContext, useState, useEffect, useContext } from 'react';
import Web3Context from './Web3Context';
import {
  address,
  usePrivateSale,
  usePublicSaleNew,
  useBUSD,
} from '../hooks/useContracts';
import { BigNumber, Contract, constants, ethers, utils } from 'ethers';

import { toast } from 'react-hot-toast';
import refHandle from '../hooks/utils';


const PresaleContextNew = createContext();
const userDefaul = {
  totalWithdrawn_: 0,
  hatcheryMiners: 0,
  sellEggs_: 0,
  tokenAmount_: 0,
  eggsMiners_: 0,
  totalDeposits_: 0,
  totalBonus_: 0,
  totalreinvest_: 0,
  hold_: 0,
  balance_: 0,
  balanceOf: 0,
  referrerAmount: 0,
  nextAssignment_: 0,
  amountOfDeposits: 0,
  checkpoint: 0,
  isUser_: false,
  referrer_: [0, 0, 0, 0, 0, 0],
  deltaWithdrawDate: 0,
  referrerCount_: [0, 0, 0],
  referrerBonus: [0, 0, 0, 0],
  time_: 0,
  premiumBonus: 0,
};
const PresaleProviderNew = ({ children }) => {  
  const { accounts, isLoaded, setupdate, update, errorMessage } =
    useContext(Web3Context);
  const [id, setid] = useState(0);
  const [update_, setupdate_] = useState(0);
  const [userData, setuserData] = useState(userDefaul);
  const [allData, setallData] = useState({
    totalUsers_: 0,
    totalInvested_: 0,
    totalReinvested_: 0,
    totalWithdrawn_: 0,
    totalDeposits_: 0,
    totalEarnings_: 0,
    tokenAmount_: 0,
    balance_: 0,
    eggPrices_: 0,
    balanceOf: 0,
    roiBase: 0,
    bonusPool: 0,
    comunityBonus: 0,
    currentRoi: 0,
    maxProfit: 0,
    minDeposit: 0,
    daysFormdeploy: 0,
  });
  const [deposit, setdeposit] = useState(0);
  const [balanceOf, setbalanceOf_] = useState(0);
  const [isApprove, setisApprove] = useState(false);
  const [userWalletData, setuserWalletData] = useState([]);
  // const { isSpinnerShown, spinnerMessage, showSpinner, hideSpinner } =
  //   useSpinner();

  const [userPlans, setuserPlans] = useState([
    {
      plan: 0,
      percent: 0,
      amount: 0,
      start: 0,
      finish: 0,
      withdrawn: 0,
    },
  ]);
  const [btcPrice, setBtcPrice] = useState(1);

  const privateSale = usePrivateSale();
  const publicSale = usePublicSaleNew();
  const Token = useBUSD();

  useEffect(() => {
    // fetch('https://api.coingecko.com/api/v3/coins/polkadot')
    //   .then((res) => res.json())
    //   .then((res) => {
    //     // console.log(res.tickers[0].last.toFixed(2),"priocesss");
    //     setBtcPrice(res.tickers[0].last.toFixed(2));
    //   });
  }, []);

  const getUserWalletData = async () => {
    if (!isLoaded) return;
    try {
      const [load, contract] = await privateSale;
      if (!load) {
        return;
      }

      const data = await contract.getUserWithdrawData();
      setuserWalletData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isLoaded) return;
    try {
      getPublicData();
      getUserData();
      balanceOfETH();
      // getUserWalletData();

      // balanceOfHandle();
      // // getUserDataDeposit();
      // allowanceHandle();
    } catch (error) {
      console.log(error);
    }
    return () => {};
  }, [accounts, isLoaded, update_]);

  const updateHandle = () => {
    setupdate_(update_ + 1);
  };
  const idHandle = (id_) => {
    setid(id_);
  };



  const balanceOfETH = async () => {
    if (
      !isLoaded &&
      accounts != '000000000000000000000000000000000000000000000'
    )
      return;
    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    // let balance_ = etherJSProvider();
    let balance_ = await provider.getBalance(accounts);
    // console.log(await provider.getBalance(accounts),"balance_");
    // return
    balance_ = Number(ethers.utils.formatEther(balance_)).toFixed(4);
    // const res =Object.assign({},userData,{balanceOf:balance_})
    setbalanceOf_(balance_);
  };

  const approveHandle = async (type) => {
    if (
      !isLoaded &&
      accounts != '000000000000000000000000000000000000000000000'
    )
      return;
    const [load, contract] = await Token;
    if (!load) return;
    const addr = address.privateSale;
    const res = await contract.approve(addr, constants.MaxUint256);
    res.wait().then(() => updateHandle());
  };



  const invest = async (investAmt, isPrivate) => {

    if (!isLoaded) {
      errorMessage();
      
      return;
    }
    const ref_ = refHandle();

    try {      
      let [load, contract] = await privateSale;
      if(!isPrivate)
      {
        [load, contract]   =await publicSale     
      }
      if (!load) return;
      investAmt = utils.parseEther(investAmt.toString());
      const res = await contract.buy(ref_,{ value: investAmt,
      gasPrice: 4e9,
      gasLimit: 2000000,
      });
      toast.success('Invest success');
      res.wait().then((value) => {
        updateHandle();
      });
    } catch (err) {
      if (err.data != undefined) toast.error(err.data.message);
      // else toast.error(err.message);
      // else toast.error(err.message.split("reason=\"")[1].split("\",")[0])
      console.log(err,"err");
    }
  };


  const withdraw = async (isPrivate) => {
    if (!isLoaded) {
      errorMessage();
      return;
    }
    const [load, contract] = await privateSale;

    if (!load) return;
    if(!isPrivate)
      {
        [load, contract]   =await publicSale     
      }

    try {
      const res = await contract.withdrawTokens();
      toast.success('withdraw success');
 
      res.wait().then((value) => {
        updateHandle();
      });
    } catch (err) {
      if (err.data != undefined) toast.error(err.data.message);
      else toast.error(err.message);
    }
  };

  const reinvestment = async () => {
    if (!isLoaded) {
      errorMessage();
      return;
    }
    const ref_ = refHandle();
    try {
      const [load, contract] = await privateSale;
      if (!load) return;
      

      const res = await contract.reInvest();
      toast.success('Invest success');
 
      res.wait().then((value) => {
        updateHandle();
      });
    } catch (err) {
      if (err.data != undefined) toast.error(err.data.message);
      else toast.error(err.message);
    }
  };

  const getUserDataDepositlenght = async () => {
    if (
      !isLoaded &&
      accounts != '000000000000000000000000000000000000000000000'
    )
      return;
    const [load, contract] = await privateSale;
    if (!load) return;
    const data = await contract.getUserAmountOfDeposits(accounts);

    const length = data.toNumber();
    // console.log(data.toNumber(),accounts);
    if (length == 0) return;
    setdeposit(length);

    let plans_ = [];

    for (let index = 0; index < length; index++) {
      const { plan, percent, amount, start, finish } =
        await contract.getUserDepositInfo(accounts, index);
      // console.log(amount,"lenghtlenght");
      plans_.push({
        plan,
        percent: percent.toNumber(),
        amount: utils.formatEther(amount),
        start: start.toNumber(),
        finish: finish.toNumber(),
        wallet: accounts,
      });
    }

    setuserPlans(plans_);
  };

  const getUserDataDeposit = async (id) => {
    if (
      !isLoaded &&
      accounts != '000000000000000000000000000000000000000000000'
    )
      return;
    const [load, contract] = await privateSale;
    if (!load) return;
    // const { plan, withdrawn, amount, start } = await contract.getUserPlans(accounts,);
    const planas = await contract.getUserPlans(accounts);
    let plans_ = [];
    const days = [10, 15, 20, 30];
    if (planas.length > 0) {
      planas.map((value) => {
        const id = value.plan.toString();
        plans_.push({
          plan: id,
          withdrawn: utils.formatEther(value.withdrawn),
          amount: utils.formatEther(value.amount),
          start: value.start.toNumber(),
          finish: days[id],
          wallet: accounts,
        });
      });
    }
    setuserPlans(plans_);

    // plans_.push({
    //   plan,
    //   percent: percent.toNumber(),
    //   amount: utils.formatEther(amount),
    //   start: start.toNumber(),
    //   finish: finish.toNumber(),
    //   wallet: accounts,
    // });
  };
  const getUserData = async () => {
    if (
      !isLoaded &&
      accounts != '000000000000000000000000000000000000000000000'
    )
      return;
    const [load, contract] = await privateSale;
    const [load2, contract2] = await publicSale;
    if (!load) return;

    try {
     
      console.log("inicio user_");
      const user_ = await contract.sales(accounts);
      console.log("init user2_");
      const user2_ = await contract2.sales(accounts);
      console.log("init user3_");
      const user3_ =  await contract2.getRefArray(accounts);
      console.log("user2 referrer",user2_.referrals);
      console.log("init referrerAmount");
      const refData = [];
      for(let i=0;i<user3_.length;i++) {
          refData.push(user3_[i]);
      }
      console.log(user_,"user_");
      console.log(user2_,"user2_");
      console.log("refDataOriginal",user3_);
      console.log("refData",refData);



      console.log();
      // const dateNumber = date.toNumber();
      // let nextNumber = data.checkpoint.toNumber();
      // nextNumber = nextNumber + 86400;
      // let interval = dateNumber > nextNumber ? 0 : nextNumber - dateNumber;
      // let time_ = interval / 3600;

      const data_ = {
        // totalWithdrawn_: Number(utils.formatEther(user_.withdraw)).toFixed(4),
        totalDeposits_: Number(utils.formatEther(user_.investAmount)).toFixed(
          4,
        ),
        totalEarnings_: Number(utils.formatEther(user2_.investAmount)).toFixed(
          4,
        ),
        totalBonus_: Number(utils.formatEther(user2_.tokenAmount)).toFixed(4),
        // // totalreinvest_: pausa?0:utils.formatEther(data.totalreinvest_),
        // hold_: Number(utils.formatEther(data.amountAvailableReinvest_)).toFixed(
        //   4,
        // ),
        balance_: Number(utils.formatEther(user_.tokenAmount)).toFixed(4),
        //       hatcheryMiners:data.hatcheryMiners_.toString(),
        // sellEggs_: utils.formatEther(data.sellEggs_),
        // eggsMiners_: data.beatsMiners_.toString(),

        // premiumBonus: user_.premiumBonus.toString(),
        // nextAssignment_: pausa ? 0 : data.nextAssignment_.toString(),
        // amountOfDeposits: data.amountOfDeposits.toString(),
        // checkpoint: pausa ? 0 : data.checkpoint.toString(),
        // isUser_: data.isUser_,
        // referrer_: data.referrer,
        // referrerCount_: [0, 0, 0],
        // referrerBonus: [0, 0, 0],
        referrerCount_: refData.map((value) => value.toString()),
        // referrerAmount: referrerAmount,
        // time_: time_.toFixed(2),
        // referrerBonus: utils.formatEther(_referrerBonus),
      };
      // console.log(data,"userData");
      // console.log(data_, 'userData');
      const res = Object.assign({}, userData, data_);
      setuserData(res);
    } catch (error) {
      console.log('Errr user', error);
      setuserData(userDefaul);
    }
  };

  const getPublicData = async () => {
    if (
      !isLoaded &&
      accounts != '000000000000000000000000000000000000000000000'
    )
      return;
    try {
      const [load, contract] = await privateSale;
      const [load2, contract2] = await publicSale;

      if (!load) return;

      // returns (uint256 _totalInvest, uint256 _balance)
      // const pausa = await contract.isPaused();

      const data = await contract.totalInvested();
      const data2 = await contract2.totalInvested();
      // const getPlayers = await contract.getPlayers();

      // const data = await contract.getBalance();
      console.log(data, 'getPublicData');

      const data_ = {
        // totalUsers_: getPlayers.toString(),
        totalInvested_: utils.formatEther(data),
        // totalReinvested_: utils.formatEther(data.totalReinvested_.toString()),
        // totalWithdrawn_: utils.formatEther(data.totalWithdrawn_.toString()),
        totalDeposits_: utils.formatEther(data2),
        // balance_: utils.formatEther(data._balance),
        // eggPrices_: utils.formatEther(data._eggPrices)
        // roiBase: data.roiBase.toString(),
        // bonusPool: data.bonusPool.toString(),
        // comunityBonus: data.comunityBonus.toString(),
        // currentRoi: Number(data.currentRoi.toString())/10,
        // maxProfit: data.maxProfit.toString(),
        // minDeposit: data.minDeposit.toString(),
        // daysFormdeploy: data.daysFormdeploy.toString(),
      };

      setallData(data_);
    } catch (error) {
      console.log('Errr public', error);
    }
  };

  const datas = {
    userData,
    allData,
    invest,
    withdraw,
    reinvestment,
    updateHandle,
    idHandle,
    id,
    btcPrice,
    userPlans,
    balanceOf,
    isApprove,
    approveHandle,
    deposit,
    userWalletData,
  };

  return (
    <PresaleContextNew.Provider value={datas}>{children}</PresaleContextNew.Provider>
  );
};

export const useSPresaleNew = () => useContext(PresaleContextNew);

export { PresaleProviderNew };
export default PresaleContextNew;