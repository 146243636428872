export function formatRange(num, level1Referrals, level2Referrals, level3Referrals,level4Referrals) {

    if (num >= 50000 && level1Referrals >= 5 && level2Referrals >= 25 && level3Referrals >= 100) {
      return 'Range: Start Boost 3';
    }
    if (num >= 21000 && level1Referrals >= 5 && level2Referrals >= 20) {
      return 'Range: Start Boost 2';
    }
    if (num >= 10000 && level1Referrals >= 5) {
      return 'Range: Start Boost 1';
    }

  }
  