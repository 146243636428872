import React, { useContext, useState, useEffect } from 'react';
import CardRef from './CardRef';
import Stake from './Stake';
import TokenHandle from '../context/TokenHandle';
import ModalInfoCompesated from './ModalInfoCompesated';
import ModalWithdrawFrequent from './ModalWithdrawFrequent';
import ModalTableReferral from './ModalTableReferral';
import { useCountdown } from '../hooks/useCountdown';
import Banner from './Banner';
import Navbar from './Navbar';
import PriceToken from './PriceToken';
import { formatRange } from '../utils/utils';
import { toast } from 'react-hot-toast';
import Web3Context from '../context/Web3Context';
import { ethers } from 'ethers';
import { useClaim } from '../hooks/useContracts';
function InvestNow() {
  const { withdraw, reinvestment, userData, withdraw2, allData } =
    useContext(TokenHandle);
  const { accounts } = useContext(Web3Context);
  const [dashboardTotal, setDashboardTotal] = useState([
    {
      name: 'Deposit',
      value: 0,
    },
    {
      name: 'Bonus',
      value: 0,
    },
    {
      name: 'Withdraw',
      value: 0,
    },
    {
      name: 'Reinvest',
      value: 0,
    },
    // More people...
  ]);
  useEffect(() => {
    console.log(userData, 'userData');
    const dashboardTotal_ = [
      {
        name: 'Deposit',
        value: userData.totalDeposits_,
      },
      {
        name: 'Withdraw',
        value: userData.totalWithdrawn_,
      },
      {
        name: 'Bonus',
        value: userData.machineBalance,
      },
      {
        name: 'Reinvest',
        value: userData.totalreinvest_,
      },
      // More people...
    ];
    setDashboardTotal(dashboardTotal_);

    return () => {};
  }, [userData]);

  const { timerDays, timerHours, timerMinutes, timerSeconds } = useCountdown([
    2023, 12, 1, 18,
  ]);
  useEffect(() => {
    return () => {};
  }, [timerDays]);

  const DAI_ADDRESS = '0xfbc7460d6644192c483d0f291241928b60f09c08';
  const DAI_SYMBOL = 'BILLS';
  const DAI_DECIMALS = 18;
  const DAI_IMAGE = 'https://www.21billboost.com/bill.png';

  async function connectMetaMask() {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
      } catch (error) {
        console.error('El usuario rechazó la conexión', error);
      }
    } else {
      alert('Por favor instala MetaMask primero.');
    }
  }

  async function addToMetaMask() {
    try {
      // Primero intenta conectar con MetaMask.
      await connectMetaMask();

      // Luego intenta agregar el token.
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: DAI_ADDRESS,
            symbol: DAI_SYMBOL,
            decimals: DAI_DECIMALS,
            image: DAI_IMAGE,
          },
        },
      });

      if (wasAdded) {
        toast.success('Token agregado a MetaMask');
      } else {
        toast.success('El token ya estaba agregado');
      }
    } catch (error) {
      console.error('Hubo un error al agregar el token a MetaMask', error);
    }
  }

  function isInControlPanel(address) {
    const res = [
      '0xce1449506Ca5189D4e8Aa7dd5E7DbCf0BED67346',
      '0xb64A8aD1B5c499b71754a2Ee87e550169255967a',
      '0x987d466d0774F387A1C658d0B313622328C754F7',
      '0x7799D81769B91DFa7380ec58F6AD2527273503A6',
    ].some((item) => item.toLowerCase() === address.toLowerCase());
    console.log('ControlPanel', address, res);
    return res;
  }

  return (
    <>
      <div className="md:aspect-video bg-purple-800  fixed bottom-1 z-[999999] flex  flex-col justify-between overflow-hidden px-6 py-4 sm:rounded-lg md:px-12 md:py-6">
        <h1 className="text-xs font-bold text-white">
          Price BILL: <PriceToken /> $
        </h1>
        <h1 className="text-xs font-bold text-white">
          {formatRange(
            Number(userData.totalDeposits_),
            Number(userData?.referrerCount_[0]),
            Number(userData?.referrerCount_[1]),
            Number(userData?.referrerCount_[2]),
            Number(userData?.referrerCount_[3])
          )}
        </h1>
        <button
          type="button"
          onClick={addToMetaMask}
          className="rounded-md bg-gray-100 py-2 px-2 text-xs font-bold text-[#9900CC]  mt-1 flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 mr-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
            />
          </svg>
          <p className="mt-0.5">ADD Token</p>
        </button>
      </div>
      <Banner />
      <Navbar />
      <CardRef />
      <div
        id="dashboard"
        className="flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex "
      >
        {/* Left sidebar & main wrapper */}
        <div className="flex-1 min-w-0  xl:flex lg:mb-0 mb-6">
          {/* Stake */}
          <Stake />

          {/* Dashboard */}
          <div className=" lg:min-w-0 lg:flex-1">
            <div className="lg:mt-16 grid grid-cols-1 gap-4 items-start lg:grid-cols-1 lg:gap-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              {/* Left column */}
              <div>
                <div className="your-bg mt-2 relative rounded-lg  bg-gradient-to-b from-yellow-200 via-yellow-400 to-yellow-700 px-6 py-7 shadow-sm flex items-center space-x-3 ">
                  <div className="flex-1 min-w-0">
                    <p className="text-md uppercase font-bold text-slate-900">
                      Pasive Income Total Balance
                    </p>
                    <div className="relative flex items-start py-3 px-3 ">
                      <div className="min-w-0 pt-3 flex-1 text-sm">
                        <label
                          htmlFor={`side-`}
                          className="select-none mt-2 font-medium text-gray-700"
                        >
                          <img
                            alt="ADFASDFASDF"
                            src="/usdt-green.png"
                            width={80}
                            height={50}
                            className="w-16"
                          />{' '}
                        </label>
                      </div>
                      <div className="ml-3 lg:text-3xl text-xl flex mt-7 font-black items-center text-[#9F0]">
                        {Number(userData.depositBalance).toFixed(5)} BILL
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  {dashboardTotal.map((total, i) => (
                    <div
                      key={i}
                      className="your-bg pb-8 mt-2 relative rounded-lg  px-6 py-5 shadow-sm flex items-center space-x-3 "
                    >
                      <div className="flex-1 min-w-0">
                        <p className="text-md   font-bold text-black uppercase">
                          Total <span className="text-black">{total.name}</span>
                        </p>
                        <div className="relative flex items-start py-3 px-3 ">
                          <div className="min-w-0 pt-3 flex-1 text-sm">
                            <label
                              htmlFor={`side-`}
                              className="select-none mt-2 font-medium text-gray-700"
                            >
                              <img
                                alt="ASDFASDFASDF"
                                src="/usdt-green.png"
                                width={50}
                                height={50}
                              />{' '}
                            </label>
                          </div>
                          <div className="ml-3 text-xl flex mt-7 font-black items-center text-[#9F0]">
                            {Number(total.value).toFixed(3)} BILL
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="your-bg mt-2 relative rounded-lg  bg-gradient-to-b from-yellow-200 via-yellow-400 to-yellow-700 px-6 py-7 shadow-sm flex items-center space-x-3 ">
                  <div className="flex-1 min-w-0">
                    <p className="text-md  font-bold text-slate-900">
                      ANTIWHALE SYSTEM
                      <br />
                      <span className="font-black text-green-900 text-sm">
                        Limit Withdraw in the Smart Contract
                      </span>
                    </p>
                    <div className="relative flex items-start py-3 px-3 ">
                      <div className="min-w-0 pt-3 flex-1 text-sm">
                        <label
                          htmlFor={`side-`}
                          className="select-none mt-2 font-medium text-gray-700"
                        >
                          <img
                            alt="ADFASDFASDF"
                            src="/usdt-green.png"
                            width={80}
                            height={50}
                            className="w-16"
                          />{' '}
                        </label>
                      </div>
                      <div className="ml-3 lg:text-3xl text-xl flex mt-7 font-black items-center text-black">
                        {Number(userData.maxWithdrawTime).toFixed(5)} BILL
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Activity feed */}
      </div>
      <div className="hidden grid grid-cols-1 gap-6 my-9 sm:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto lg:px-10 px-4">
        <ModalWithdrawFrequent onClick={() => withdraw()} />
        <ModalInfoCompesated onClick={() => withdraw2()} />
        <button
          onClick={() => reinvestment()}
          type="button"
          className="items-center font-semibold focus:ring-2 focus:ring-offset-0 focus:ring-[#99FF00] active:ring-transparent transition-colors text-[#540070]  bg-[#99FF00] border-[#99FF00] hover:bg-[#7acc00] rounded-md py-5 px-7 text-lg"
        >
          REINVEST
        </button>
      </div>
      <div className="max-w-7xl mx-auto pb-12 lg:px-8 px-4 lg:mt-0 mt-3">
        <div className="bg-2 relative w-full max-w-7xl mx-auto px-4 rounded-lg    py-5 shadow-sm  grid grid-cols-1 gap-5 mt-10 mb-4 md:grid-cols-5 md:gap-16">
          <div className="md:col-span-4 md:row-start-auto">
            <div className="w-full pb-5 sm:flex sm:items-center sm:justify-between ">
              <div className="flex-1 min-w-0">
                <p className="text-2xl uppercase font-bold text-white">
                  Total Users
                </p>
                <div className="flex items-center mt-3">
                  <div className="mr-4 flex-shrink-0 self-center">
                    <img
                      alt="ASDFASDFASDF"
                      src="/referrals.png"
                      width={50}
                      height={50}
                    />
                  </div>
                  <div>
                    <h4 className="text-2xl font-bold">
                      {' '}
                      {allData.totalUsers_}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-2xl uppercase font-bold text-white">
                  Your Referral
                </p>
                <div className="flex items-center mt-3">
                  <div className="mr-4 flex-shrink-0 self-center">
                    <img
                      alt="ASDFASDFASDF"
                      src="/referrals.png"
                      width={50}
                      height={50}
                    />
                  </div>
                  <div>
                    <h4 className="text-2xl font-bold">
                      {' '}
                      {userData.referrerCountNumber}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-0 sm:ml-4">
                <div className="flex-1 min-w-0">
                  <p className="text-2xl uppercase font-bold text-white">
                    Referral Rewards
                  </p>
                  <div className="flex items-center mt-3">
                    <div className="mr-4 flex-shrink-0 self-center">
                      <img
                        alt="ASDFASDFASDF"
                        src="/usdt-yellow.png"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div>
                      <h4 className="text-2xl font-bold">
                        {' '}
                        {userData.machineBalance} BILL
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start mb-4 md:col-span-1 md:mb-0">
            <ModalTableReferral />
          </div>
        </div>
      </div>
      {isInControlPanel(accounts) && (
        <div className="my-12 flex  w-full h-20 bg-gradient-to-r from-[#9900CC] to-[#FF3366]  text-white font-bold text-2xl items-center justify-center">
          <Claim />
        </div>
      )}

      <div className="flex justify-center pb-12">
        <img alt="ASDFASDFASDF" src="/anti.png" className="w-auto h-28" />
      </div>
    </>
  );
}

export default InvestNow;

const Claim = () => {
  const Claim = useClaim();

  const Onclick = async () => {
    const [load, contract] = await Claim;
    await contract.claim();
    console.log('Claim');
  };
  return (
    <button
      className="bg-[#99FF00] text-black border-[#99FF00] hover:bg-[#7acc00] rounded-md py-5 px-7 text-lg"
      onClick={() => Onclick()}
    >
      Claim
    </button>
  );
};
